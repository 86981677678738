import React from "react";

import "./style.css";
import { useEffect } from "react";
import { useInfoMutation } from "../../store/pageApi2";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const Application = () => {
  const [info, { data: infoData }] = useInfoMutation()
  const [kong, setkong] = useState(true)
  const nav = useNavigate()
  const { t, i18n } = useTranslation();
  useEffect(() => {
    info({
      token: localStorage.getItem('li_token')
    }).then(res => {
      console.log(res.data.rs)
      if (res.data.rs == -1) {
        if (res.data.internal == 1) {
          setkong(false)
        } else {
          setkong(true)
        }

        //  nav('/home/information',{replace:true})
      } else if (res.data.rs === 0) {
        localStorage.removeItem('li_token')
        localStorage.removeItem('li_user_id')
        nav('/login', { replace: true })
      } else if (res.data.rs === 1) {
        setkong(false)
      } else {

      }

    });
  }, [])
  const item = () => {
    return (
      <>
        
        <div className="overlap-wrapper">
          <div className="overlap">
            <div className="li-wrapper">

            </div>
            <img src={t("is_en") == 1 ? "/img/logo-en.png" : "/img/login/logo.png"} alt="" className="logo" />
            <div className="text-wrapper-4">{t("j_title")}</div>
            <div className="text-wrapper-5">{t("j_my_id")}：{localStorage.getItem('li_user_id')}</div>
            {
              infoData ? <>
                {
                  infoData.site1 ? <>
                    <div className="text-wrapper-wanyan">晚宴座位号：{infoData.site1}</div>
                  </> : null
                }
                {
                  infoData.site2 ? <>
                    <div className="text-wrapper-dahui">大会座位号：{infoData.site2}</div>
                  </> : null
                }

              </> : null
            }
            
            <div className="group-3">
              <div className="text-wrapper-6">{t("j_my_code")}</div>
              <img className="mask-group" src={`https://h5.aiweber.cn/images/qr/${localStorage.getItem('li_user_id')}.png?id=234`}></img>
            </div>
          </div>
          <div className="shuoming">
            {t("shuoming")}

            </div>

        </div>
        <div className="group-4">
          <div className="text-wrapper-7" style={t('is_en') == 1 ? { left: '-80px' } : {}}>{t("j_tip")}！</div>
          <img
            className="image"
            alt="Image"
            src="/img/img2/---1.png"
            style={t('is_en') == 1 ? { left: '-150px' } : {}}
          />
        </div>
        <div className="group-wrapper">
          <div className="group-2">
            <div className="text-wrapper-2">{t("j_my_intro")}</div>
            <div className="text-wrapper-3">
              {t("j_my_intro_desc")}

            </div>
          </div>
        </div>
      </>
    )
  }
  return (
    <div className="application">
      <div className="div">


        {
          kong ? <>
            <>
              <div className="group">

              </div>
              <div className="overlap-wrapper">
                <div className="overlap">
                  <div className="li-wrapper">

                  </div>
                  <img src="/img/login/logo.png" alt="" className="logo" />
                  <div className="text-wrapper-4">{t("j_title")}</div>
                  <div className="group-3">

                  </div>
                </div>
              </div>
              <div className="group-4">

              </div>
              <div className="group-wrapper">
                <div className="group-2">
                  <div className="text-wrapper-2">{t("j_my_intro")}</div>
                  <div className="text-wrapper-3">
                    {t("j_my_intro_desc")}

                  </div>
                </div>
              </div>
            </>
          </> : item()
        }
      </div>
    </div>
  );
};

export default Application