import { createSlice } from "@reduxjs/toolkit";

const pageSlice=createSlice({
    name:'pageSlice',
    initialState:{
        xuan:1
    },
    reducers:{
        changeXuan(state,action){
            state.xuan=action.payload
            console.log(state.xuan)
        }
    }
})

export const {
    changeXuan
}=pageSlice.actions

export default pageSlice